/* You can add global styles to this file, and also import other style files */
// If you want to override variables do it here
// Import styles with default layout.

@import "variables";

$enable-ltr: true;
$enable-rtl: true;

@import "@coreui/coreui-pro/scss/coreui";
//  @import "@coreui/coreui-pro/scss/themes/dark";

// Import Chart.js custom tooltips styles
@import "@coreui/chartjs/scss/coreui-chartjs";

// @import "layout";
@import "theme";

// Some temp fixes
@import "fixes";

// Prism.js
@import "examples";

// If you want to add something do it here
@import "custom";

// @import '@fontsource/lexend/400.css'; /* Regular */
// @import '@fontsource/lexend/500.css'; /* Medium */
// @import '@fontsource/lexend/700.css'; /* Bold */
mat-icon{
    font-family: 'Material Icons' !important;
  }


  // @font-face {
  //   font-family: bogle-regular;
  //   src: url(/assets/fonts/Bogle-Regular.otf) format("opentype");
  // }

  // @font-face {
  //   font-family: bogle-bold;
  //   src: url(/assets/fonts/Bogle-Bold.otf) format("opentype");
  // }

  // @font-face {
  //   font-family: bogle-light;
  //   src: url(/assets/fonts/Bogle-Light.otf) format("opentype");
  // }

  // @font-face {
  //   font-family: bogle-thin;
  //   src: url(/assets/fonts/Bogle-Thin.otf) format("opentype");
  // }

  // @font-face {
  //   font-family: bogle-italic;
  //   src: url(/assets/fonts/Bogle-Italic.otf) format("opentype");
  // }

  .periwinkle { color: #7f5aed; }
.teal { color: #00AACA; }
.dgreen { color: #00DAC5; }
.blue { color: #3700B3;}
.ochre { color: #CF6679}
.errorred { color: #B00020;}
.cyan { color: #0dcaf0;}
.pgreen {color:#3C9E4D;}


body { 
  background: url(/assets/images/back.jpg) no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.dark-theme .sidebar {
  --cui-sidebar-nav-title-color: #CD1812;
  --cui-sidebar-nav-title-margin-top:0.01rem;
  --cui-sidebar-nav-link-padding-y:0.01rem;
}

.card {
  box-shadow: 0 0 20px 5px #86070726 !important;
    border-radius: 0.6em !important;
}

body {
  font-family: Lexend, sans-serif;
  }

